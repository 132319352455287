import MetamaskAuth from '../MetamaskAuth'
import { AppHeader, MainHeader, LogoContainer } from './Header.styled'

function Header({ userBalance, userAddress, chainId, setUserAddress }) {
  return (
    <AppHeader>
      <MainHeader>
        <LogoContainer>
          <a href='https://metarun.game/' target='_blank' rel='noreferrer'>
            <picture>
              <source
                media='(max-width: 768px)'
                srcSet={require('../../images/SmallLogo.svg').default}
              ></source>
              <img
                src={require('../../images/Logo.svg').default}
                alt='logo'
              ></img>
            </picture>
          </a>
        </LogoContainer>
        <MetamaskAuth
          userAddress={userAddress}
          chainId={chainId}
          setUserAddress={setUserAddress}
          userBalance={userBalance}
        />
      </MainHeader>
    </AppHeader>
  )
}

export default Header
