import { useState, useEffect, useReducer } from 'react';
import { ethers, BigNumber, utils } from 'ethers';
// import { utils } from 'ethers';
import { Button } from '../Button';
import Spinner from '../Spinner';
import Progressbar from '../Progressbar';
import networks from '../../networks.json';
import { CHAIN_ID } from '../../constants';
import {
  getLegacyMrun,
  getTaxableMrun,
  getMigrator,
} from '../../api/contracts';

import {
  StyledCard,
  CardHeader,
  CardHeaderItem,
  CardBody,
  CardBalance,
  CardBalanceTitle,
  Border,
  SwapInputContainer,
  SwapInputLabel,
  SwapInput,
  SwapError,
  TransactionLink,
  ProgressBarTitle,
  ProgressBarInfo
} from './Swap.styled';
import { shortenAddress } from '../../utils';

export function SwapCard({
  allowance,
  userAddress,
  chainId,
  json,
  alreadyMigrated,
  getAllowance,
  getAlreadyMigrated,
  totalCap,
  totalMigrated
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [inputValidity, setInputValidity] = useState(false);
  const [amount, setAmount] = useState('');
  const [txHash, setTxHash] = useState();
  const [isApproveProcess, setIsApproveProcess] = useState(false);
  const [legacyMrunBalance, setLegacyMrunBalance] = useState('0.00');
  const [taxableMrunBalance, setTaxableMrunBalance] = useState('0.00');
  const [forced, forceUpdate] = useReducer((x) => x + 1, 0);

  const amountHandler = (e) => {
    e.preventDefault();
    setAmount(e.target.value);
  };

  async function handleAddToken() {
    try {
      await window.ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: {
            address: networks[CHAIN_ID].taxableMrunContract.address,
            symbol: 'MRUN',
            decimals: 18,
            // image: tokenImage,
          },
        },
      });
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (chainId === networks[CHAIN_ID].params.chainId && userAddress) {
      try {
        const LegacyMrun = getLegacyMrun();
        LegacyMrun.balanceOf(userAddress).then((res) => {
          const mrun = utils.formatEther(res);
          const calculatedAmount = utils.formatEther(BigNumber.from(json.cap).sub(BigNumber.from(alreadyMigrated)));
          setLegacyMrunBalance(mrun);
          setAmount(calculatedAmount);

        });
      } catch (e) {
        console.log(e);
      }
    }
  }, [userAddress, chainId, forced]);

  useEffect(() => {
    if (chainId === networks[CHAIN_ID].params.chainId && userAddress) {
      try {
        const TaxableMrun = getTaxableMrun();
        TaxableMrun.balanceOf(userAddress).then((res) => {
          const mrun = Number(utils.formatEther(res));
          setTaxableMrunBalance(mrun.toFixed(4));
        });
      } catch (e) {
        console.log(e);
      }
    }
  }, [userAddress, chainId, forced]);

  function submitHandler(e) {
    e.preventDefault();
    setIsLoading(true);
    if (Number(ethers.utils.parseEther(amount)) > Number(allowance)) {
      approveHandler();
    } else {
      swapHandler();
    }
  }

  const approveHandler = () => {
    setIsApproveProcess(true);
    setIsLoading(true);
    const MetarunToken = getLegacyMrun();
    MetarunToken.approve(
      networks[CHAIN_ID].migratorContract.address,
      ethers.utils.parseEther(
        utils.formatEther(BigNumber.from(json.cap).sub(BigNumber.from(alreadyMigrated))).toString()
      )
    )
      .then((tx) => {
        setTxHash(tx.hash);
        tx.wait()
          .then(() => {
            setIsApproveProcess(false);
            getAllowance();
            setIsLoading(false);
            setTxHash();
            swapHandler();
          })
          .catch((err) => {
            setIsApproveProcess(false);
            setTxHash();
            setIsLoading(false);
            console.log(err);
          });
      })
      .catch((err) => {
        setIsApproveProcess(false);
        setTxHash();
        setIsLoading(false);
        console.log(err);
      });
  };

  const swapHandler = () => {
    setIsLoading(true);
    const Migrator = getMigrator();
    if (inputValidity) {
      Migrator.migrate(
        ethers.utils.parseEther(amount).toString(),
        json.cap,
        json.signature,
        {
          gasLimit: 500000,
        }
      )
        .then((tx) => {
          setTxHash(tx.hash);
          tx.wait()
            .then(() => {
              setIsLoading(false);
              setTxHash();
              forceUpdate();
              getAlreadyMigrated();
              getAllowance();
            })
            .catch((err) => {
              setIsLoading(false);
              setTxHash();
              console.log(err);
            });
        })
        .catch((err) => {
          setIsLoading(false);
          setTxHash();
          console.log(err);
        });
    }
  };

  useEffect(() => {
    const swapAmountNumber = amount ? Number(amount.replaceAll(',', '.')) : 0;

    if (isNaN(swapAmountNumber) || swapAmountNumber <= 0) {
      setInputValidity(false);
    } else {
      setInputValidity(true);
    }
  }, [amount]);

  const NoAddress = () => {
    return <CardBody margin={'40px 0'}>Please connect wallet</CardBody>;
  };

  const WrongChain = () => {
    return <CardBody margin={'40px 0'}>Please switch chain</CardBody>;
  };

  const NoJSON = () => {
    return (
      <CardBody margin={'40px 0'}>
        Your address {shortenAddress(userAddress)} is not eligible
      </CardBody>
    );
  };

  return (
    <StyledCard>
      {isLoading ? (
        <>
          {isApproveProcess ? <p>Approving..</p> : <p>Migrating...</p>}
          <div style={{ margin: '20px 0 50px' }}>
            <Spinner size={'50px'} />
          </div>
          {txHash && (
            <TransactionLink
              target='_blank'
              href={
                networks[CHAIN_ID]
                  ? `${networks[CHAIN_ID].params.blockExplorerUrls[0]}tx/${txHash}`
                  : ''
              }
            >
              {`TX: ${shortenAddress(txHash)}`}
            </TransactionLink>
          )}
        </>
      ) : (
        <>
          <CardHeader>
            <CardHeaderItem>Swap legacy MRUN to taxable</CardHeaderItem>
          </CardHeader>
          <Border />
          {!userAddress ? (
            <NoAddress />
          ) : chainId !== networks[CHAIN_ID].params.chainId ? (
            <WrongChain />
          ) : !json ? (
            <NoJSON />
          ) : (
            <>
              <CardBody border>
                <CardBalance>
                  <ProgressBarTitle>
                    Migrate Progress
                  </ProgressBarTitle>
                  <Progressbar completed={Number(totalMigrated) / Number(utils.formatEther(totalCap.total_cap)) * 100} />
                  <ProgressBarInfo>
                    Migrated: {' '}
                    {Number(totalMigrated).toFixed(4)}{' '} MRUN
                    from {' '}
                    {totalCap?.total_cap &&
                      Number(utils.formatEther(totalCap.total_cap)).toFixed(4)}{' '} MRUN
                  </ProgressBarInfo>
                </CardBalance>
                <CardBalance>
                  <CardBalanceTitle>
                    Legacy MRUN balance: <b>{Number(legacyMrunBalance).toFixed(4)}{' '}
                      MRUN</b>
                  </CardBalanceTitle>
                  <CardBalanceTitle>
                    Taxable MRUN balance: <b>{taxableMrunBalance} MRUN</b>
                  </CardBalanceTitle>
                  <CardBalanceTitle>
                    Available to swap:{' '}
                    <b>
                      {json?.cap &&
                        (utils.formatEther(BigNumber.from(json.cap).sub(BigNumber.from(alreadyMigrated))))}{' '}
                      MRUN
                    </b>
                  </CardBalanceTitle>
                </CardBalance>
              </CardBody>
              <CardBody border>
                <SwapInputContainer>
                  <SwapInputLabel>Amount</SwapInputLabel>
                  <SwapInput
                    invalid={
                      Number(amount) >
                      utils.formatEther(BigNumber.from(json.cap).sub(BigNumber.from(alreadyMigrated))) || !inputValidity
                    }
                    value={amount}
                    placeholder='0.1'
                    onChange={amountHandler}
                  />
                  {!inputValidity ? (
                    <SwapError>Invalid value</SwapError>
                  ) : (
                    Number(amount) >
                    utils.formatEther(BigNumber.from(json.cap).sub(BigNumber.from(alreadyMigrated))) && (
                      <SwapError>Not enough available MRUN balance</SwapError>
                    )
                  )}
                </SwapInputContainer>
              </CardBody>
              <Border />
              <CardBody
                margin='14px 0 0'
                border
                mobileMargin='14px 0 0'
                padding='14px 0 0'
              >
                <Button
                  variant='contained'
                  size='extralong'
                  onClick={submitHandler}
                  disabled={
                    !inputValidity ||
                    Number(amount) >
                    utils.formatEther(BigNumber.from(json.cap).sub(BigNumber.from(alreadyMigrated)))
                  }
                >
                  SWAP
                </Button>
              </CardBody>
              <CardBody
                margin='14px 0 0'
                border
                mobileMargin='14px 0 0'
                padding='14px 0 0'
              >
                <Button variant='contained' onClick={handleAddToken}>
                  Add taxable MRUN to Metamask
                </Button>
              </CardBody>
            </>
          )}
        </>
      )}
    </StyledCard>
  );
}
