import styled from 'styled-components'

export const AppHeader = styled.div`
  padding: 0 60px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  @media (max-width: 768px) {
    padding: 0 12px;
  }
`

export const MainHeader = styled.div`
  margin: 0 auto;
  box-sizing: border-box;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) {
    padding: 12px;
  }
`

export const LogoContainer = styled.div`
  margin-right: auto;
`
