import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import { SpinnerWrapper } from './Spinner.styled'

export default function Spinner({ size }) {
  return (
    <SpinnerWrapper size={size}>
      <CircularProgress size={size} sx={{ color: '#499EFF' }} />
    </SpinnerWrapper>
  )
}
