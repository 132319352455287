import { ethers } from 'ethers'
import { CHAIN_ID } from '../../constants'
import networks from '../../networks.json'
import LegacyMrunAbi from '../../abis/LegacyMrunAbi.json'
import TaxableMrunAbi from '../../abis/TaxableMrunAbi.json'
import MigratorAbi from '../../abis/MigratorAbi.json'


export function getLegacyMrun() {
  const provider = new ethers.providers.Web3Provider(window.ethereum)
  const signer = provider.getSigner()
  const LegacyMrun = new ethers.Contract(
    networks[CHAIN_ID].legacyMrunContract.address,
    LegacyMrunAbi,
    signer
  )
  return LegacyMrun
}

export function getTaxableMrun() {
  const provider = new ethers.providers.Web3Provider(window.ethereum)
  const signer = provider.getSigner()
  const TaxableMrun = new ethers.Contract(
    networks[CHAIN_ID].taxableMrunContract.address,
    TaxableMrunAbi,
    signer
  )
  return TaxableMrun
}

export function getMigrator() {
  const provider = new ethers.providers.Web3Provider(window.ethereum)
  const signer = provider.getSigner()
  const Migrator = new ethers.Contract(
    networks[CHAIN_ID].migratorContract.address,
    MigratorAbi,
    signer
  )
  return Migrator
}