import styled from 'styled-components';

export const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-height: 200px;
  width: 50%;
  background: linear-gradient(
    184.67deg,
    rgba(72, 157, 254, 0.63) 1.89%,
    rgba(34, 7, 90, 0.42) 96.23%
  );
  background: rgba(16, 16, 44, 0.8);
  border: 1px solid rgba(73, 158, 255, 0.35);
  backdrop-filter: blur(10px);
  box-sizing: border-box;
  margin: auto;
  border-radius: 20px;
  padding: 24px;
  color: white;
  @media (max-width: 768px) {
    padding: 20px 16px;
    border-radius: 12px;
  }
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 14px;
  @media (max-width: 768px) {
    padding-bottom: 14px;
    margin-bottom: 0px;
    gap: 3px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
`;
export const Border = styled.div`
  height: 1px;
  width: 100%;
  background: rgba(73, 158, 255, 0.35);
  background: linear-gradient(
    278.44deg,
    rgba(73, 158, 255, 0.35) 42.55%,
    rgba(95, 81, 255, 0.35) 84.23%
  );
  @media (max-width: 768px) {
    display: none;
  }
`;
export const CardHeaderItem = styled.h2`
  color: white;
  font-size: 20px;
  font-style: italic;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 1px;
  text-align: left;
  text-transform: uppercase;
  @media (max-width: 768px) {
    margin-bottom: 24px;
  }
`;

export const CardBody = styled.div`
  text-align: center;
  justify-content: space-between;
  align-items: center;
  margin: ${({ margin }) => {
    if (margin) {
      return margin;
    }
    return '0';
  }};
  @media (max-width: 768px) {
    border-top: ${({ border }) => {
    if (border) {
      return '1px solid rgba(255, 255, 255, 0.1)';
    }
    return 'none';
  }};
    margin: ${({ mobileMargin }) => {
    if (mobileMargin) {
      return mobileMargin;
    }
    return '0';
  }};

    padding: ${({ padding }) => {
    if (padding) {
      return padding;
    }
    return '7px 0 0';
  }};
  }
`;

export const CardBalance = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
`;

export const CardBalanceTitle = styled.p`
  margin: 0;
  padding: 0;
  color: #7a97df;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
`;

export const CardIconLogo = styled.span`
  position: relative;
  left: 4px;
  top: 3px;
`;

export const SwapInputContainer = styled.div`
  box-sizing: border-box;
  position: relative;
  margin-top: 30px;
  margin-bottom: 30px;
  width: 100%;
`;

export const SwapInputLabel = styled.label`
  box-sizing: border-box;
  display: block;
  color: #a3a4a9;
  position: absolute;
  top: 18px;
  left: 23px;
  padding: 1px;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.4px;
  @media (max-width: 768px) {
    font-size: 10px;
    line-height: 15px;
    letter-spacing: 0.5px;
    top: 10px;
    left: 16px;
  }
`;
export const SwapInput = styled.input`
  font-family: 'OpenSans';
  display: block;
  font-size: 24px;
  width: 100%;
  box-sizing: border-box;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0.5px;
  padding: 40px 24px 18px;
  color: white;
  background-color: ${({ invalid }) => {
    if (invalid) {
      return '#ED49151A ';
    }
    return 'inherit';
  }};
  background-clip: padding-box;
  border: 1px solid #2f313b;
  border-color: ${({ invalid }) => {
    if (invalid) {
      return '#322328';
    }
    return '#2f313b';
  }};
  border-radius: 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  &::placeholder {
    color: #a3a4a9;
    opacity: 0.4;
  }
  &:focus {
    color: white;
    outline: 0;
    box-shadow: 0 0 0 1px rgba(158, 158, 158, 0.25);
  }
  &:disabled {
    background-color: #262a35;
    color: #a3a4a9;
  }
  @media (max-width: 768px) {
    font-size: 14px;
    max-width: 310px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0.5px;
    border-radius: 12px;
    padding: 26px 16px 10px;
  }
`;
export const SwapError = styled.div`
  margin-left: 10px;
  color: red;
  font-size: 12px;
  position: absolute;
  width: 100%;
`;

export const TransactionLink = styled.a`
  display: block;
  text-align: center;
  color: #fff;
  text-decoration: underline;

  &:hover {
    color: #fff;
    text-decoration: none;
  }
`;

export const ProgressBarTitle = styled.div`
  color: #7a97df;
  font-size: 18px;
  font-weight: 700;
  text-align: left;
  margin-bottom: 10px;
`;

export const ProgressBarInfo = styled.div`
  color: #7a97df;
  font-size: 17px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: left;
  margin-top: 10px;
`;

