import styled from 'styled-components';

export const StyledBorder = styled.span`
  background: linear-gradient(
    88.82deg,
    #489dfe 11.9%,
    #3970fe 45.06%,
    #5e51ff 76.01%
  );
  width: auto;
  border-radius: ${({ size }) => {
    switch (size) {
      case 'rounded':
        return '18px';
    }
    return '8px';
  }};
  box-shadow: 0;
  transform: ${({ size }) => {
    switch (size) {
      case 'rounded':
        return 'none';
    }
    return 'skew(-16deg, 0)';
  }};
  color: #122dff;
  display: inline-block;
  font-size: 20px;
  padding: 1px;
  text-decoration: none;
  transition: transform ease-in 0.1s, box-shadow ease-in 0.25s;
  max-width: 100%;
  &:disabled,
  &[disabled] {
    box-shadow: none;
    opacity: 0.7;
  }
  &:active {
    transform: ${({ size }) => {
      switch (size) {
        case 'rounded':
          return 'scale(0.9)';
      }
      return 'skew(-16deg, 0) scale(0.9)';
    }};
    opacity: 0.9;
    box-shadow: 0 2px 25px #489dfe9c;
  }
  &:disabled&:active,
  &[disabled]&:active {
    transform: ${({ size }) => {
      switch (size) {
        case 'rounded':
          return 'none';
      }
      return 'skew(-16deg, 0)';
    }};
    opacity: 0.7;
    box-shadow: none;
  }
  @media (max-width: 768px) {
    border-radius: 8px;
    width: ${({ size }) => {
      switch (size) {
        case 'extralong':
          return '100%';
      }
      return ' auto';
    }};
    max-width: 100%;
  }
`;

export const StyledButton = styled.button`
  position: relative;
  border: none;
  width: auto;
  font-family: OpenSans, sans-serif;
  font-size: 16px;
  font-style: ${({ size }) => {
    switch (size) {
      case 'rounded':
        return 'italic';
    }
    return ' normal';
  }};
  font-weight: 700;
  line-height: 26px;
  letter-spacing: ${({ size }) => {
    switch (size) {
      case 'rounded':
        return '0.4px';
    }
    return ' 1px';
  }};
  text-align: center;
  text-transform: ${({ size }) => {
    switch (size) {
      case 'rounded':
        return 'none';
    }
    return ' uppercase';
  }};
  background: ${({ variant, invalid }) => {
    switch (variant) {
      case 'contained':
        return 'linear-gradient(88.69deg, #489DFE 7.92%, #3970FE 44.55%, #5F59FF 78.73%);';
      case 'staking':
        return '#1f2863';
      case 'modal':
        if (invalid) {
          return '#2a1a2a';
        } else {
          return 'rgb(16, 16, 44)';
        }
    }
    return '#1b1a4e';
  }};
  display: block;
  padding: ${({ size }) => {
    switch (size) {
      case 'extralong':
        return '13px 150px';
      case 'rounded':
        return '14px 24px';
    }
    return ' 12px 20px';
  }};

  border-radius: ${({ size }) => {
    switch (size) {
      case 'rounded':
        return '18px';
    }
    return '8px';
  }};
  min-height: 0;
  min-width: auto;
  color: white;
  transition: opacity 0.5s, background 1s;
  cursor: pointer;
  line-height: 24px;
  &:disabled,
  &[disabled] {
    cursor: not-allowed;
    color: rgba(255, 255, 255, 0.3);
  }
  &:disabled:hover&:after,
  &[disabled]:hover&:after {
    display: none;
  }
  &:hover&:after {
    content: '';
    position: absolute;
    border-radius: ${({ size }) => {
      switch (size) {
        case 'rounded':
          return '18px';
      }
      return '8px';
    }};

    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: white;
    opacity: 0.1;
  }
  @media (max-width: 768px) {
    min-height: 0;
    font-size: ${({ size }) => {
      switch (size) {
        case 'extralong':
          return '16px';
      }
      return ' 13px';
    }};
    border-radius: 8px;
    min-width: auto;
    line-height: 24px;
    padding: ${({ size }) => {
      switch (size) {
        case 'extralong':
          return '13px 50px';
        case 'rounded':
          return '6px 12px';
        case 'swap':
          return '10px 48px';
      }
      return ' 5px 14px';
    }};
    width: ${({ size }) => {
      switch (size) {
        case 'extralong':
          return '100%';
        case 'modal':
          return '100%';
      }
      return 'auto';
    }};
    &:hover&:after {
      border-radius: 8px;
    }
  }
`;
