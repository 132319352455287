import { API_HOST } from '../constants';

export async function getJSON(address) {
  return await fetch(`${API_HOST}/caps/${address}.json`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw response;
    })
    .catch((err) => {
      throw new Error(err);
    });
}

export async function getTotalCap() {
  return await fetch(`${API_HOST}/caps/total.json`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw response;
    })
    .catch((err) => {
      throw new Error(err);
    });
}
