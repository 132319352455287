import React, { useEffect } from 'react'
import { Button } from '../Button'
import networks from '../../networks.json'
import { CHAIN_ID } from '../../constants'
import { shortenAddress } from '../../utils'
import { utils } from 'ethers'

import {
  StatusCircle,
  ConnectButton,
  ConnectAddress,
  ConnectContainer,
  ConnectBalance,
  HiddenText,
  ConnectBalanceSymbol,
  ButtonWrapper,
  NetworkContainer,
  NetworkText,
  NetworkImg,
} from './MetamaskAuth.styled'

export default function MetamaskAuth({
  userBalance,
  userAddress,
  chainId,
  setUserAddress,
}) {
  const dappUrl = window.location.href.split('//')[1]
  const metamaskAppDeepLink = 'https://metamask.app.link/dapp/' + dappUrl

  function Connect() {
    if (!window.ethereum) {
      return (
        <a href={metamaskAppDeepLink} target='_blank' rel='noreferrer'>
          <Button variant='contained' size='rounded'>
            Connect Wallet
          </Button>
        </a>
      )
    }

    return (
      <ButtonWrapper>
        <Button variant='contained' size='rounded' onClick={() => connect()}>
          Connect Wallet
        </Button>
      </ButtonWrapper>
    )
  }

  function Address() {
    return (
      <>
        <NetworkContainer>
          <NetworkImg
            src={require('../../images/BNB.svg').default}
            alt='bnb'
            width='32'
            height='32'
          ></NetworkImg>
          <NetworkText>{networks[CHAIN_ID].name}</NetworkText>
        </NetworkContainer>
        <ConnectContainer balance>
          <ConnectBalance>
            {userBalance || 0}{' '}
            <ConnectBalanceSymbol>
              {networks[CHAIN_ID].params.nativeCurrency.symbol}
            </ConnectBalanceSymbol>
          </ConnectBalance>
          <ConnectAddress>
            {shortenAddress(userAddress)}
            <StatusCircle color='green' />
          </ConnectAddress>
        </ConnectContainer>
      </>
    )
  }

  async function connect() {
    const accounts = await window.ethereum.request({
      method: 'eth_requestAccounts',
    })

    setUserAddress(utils.getAddress(accounts[0]))
  }

  async function checkIfWalletIsConnected() {
    if (window.ethereum) {
      const accounts = await window.ethereum.request({
        method: 'eth_accounts',
      })

      if (accounts.length > 0) {
        const account = accounts[0]
        setUserAddress(utils.getAddress(account))
      }
    }
  }

  async function connectToBlockchain() {
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: networks[CHAIN_ID].params.chainId }],
      })
    } catch (error) {
      // This error code indicates that the chain has not been added to MetaMask.
      if (error.code === 4902) {
        try {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [networks[CHAIN_ID].params],
          })
        } catch (error) {
          console.error(error)
        }
      } else {
        console.error(error)
      }
    }
  }

  useEffect(() => {
    checkIfWalletIsConnected()
  }, [])

  return (
    <>
      {chainId &&
      userAddress &&
      chainId === networks[CHAIN_ID].params.chainId ? (
        <Address />
      ) : chainId && chainId !== networks[CHAIN_ID].params.chainId ? (
        <ConnectButton onClick={connectToBlockchain}>
          Wrong network{' '}
          <HiddenText> (switch to {networks[CHAIN_ID].name}) </HiddenText>
          <StatusCircle color='red' />
        </ConnectButton>
      ) : (
        <Connect />
      )}
    </>
  )
}
