import styled from 'styled-components'

export const ConnectContainer = styled.div`
  font-family: OpenSans, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.4px;
  text-align: center;
  background-color: #1b1a37;
  border-radius: 20px;
  color: white;
  border: 1px solid #1b1a37;
  display: flex;
  margin-left: 12px;
  align-self: stretch;
  padding-left: ${({ balance }) => {
    if (balance) {
      return '20px'
    }
    return '0'
  }}};
  @media (max-width: 768px) {
    padding-left: 0px;
    border-radius: 12px;
    margin-left: 8px;
  }
`

export const ConnectBalance = styled.p`
  margin: 15px 0;
  font-weight: 700;
  font-style: italic;
  @media (max-width: 768px) {
    display: none;
  }
`
export const ConnectBalanceSymbol = styled.span`
  color: #9fa1a6;
`

export const ConnectButton = styled.button`
  font-family: OpenSans, sans-serif;
  display: flex;
  align-items: center;
  font-style: italic;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.4px;
  text-align: center;
  background-color: #ed49151a;
  border-radius: 20px;
  color: #ed4915;
  border: none;
  cursor: pointer;
  margin-left: 0;
  padding: 14px 24px;
  @media (max-width: 768px) {
    margin-left: 0px;
    background-color: #1b1a37;
    font-size: 14px;
    padding: 0 9px;
  }
`

export const ConnectAddress = styled.button`
  font-family: OpenSans, sans-serif;
  display: flex;
  align-items: center;
  font-style: italic;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.4px;
  text-align: center;
  background-color: black;
  border-radius: 20px;
  color: white;
  border: none;
  margin-left: 20px;
  padding: 13px 24px;
  @media (max-width: 768px) {
    margin-left: 0px;
    background-color: #1b1a37;
    font-size: 14px;
    padding: 0 9px;
  }
`

export const StatusCircle = styled.div`
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-left: 12px;
  border-radius: 50%;
  background: ${({ color }) => {
    switch (color) {
      case 'red':
        return 'red'
      case 'green':
        return 'linear-gradient(158.75deg, #70D5C4 11.4%, #5FD4D2 86%)'
      default:
        return 'black'
    }
  }};
  @media (max-width: 768px) {
    margin-left: 4px;
  }
`

export const HiddenText = styled.span`
  @media (max-width: 768px) {
    display: none;
  }
`

export const ButtonWrapper = styled.div`
  margin-left: 16px;
  @media (max-width: 768px) {
    margin-left: 8px;
  }
`

export const NetworkContainer = styled.div`
  font-family: OpenSans, sans-serif;
  font-size: 16px;
  font-style: italic;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.4px;
  text-align: center;
  background-color: #1b1a37;
  border-radius: 20px;
  color: white;
  border: none;
  display: flex;
  padding: 12px 14px;
  align-items: center;
  @media (max-width: 768px) {
    border-radius: 12px;
    padding: 5px;
  }
`

export const NetworkText = styled.p`
  margin: 0;
  margin-left: 8px;
  @media (max-width: 768px) {
    display: none;
  }
`

export const NetworkImg = styled.img`
  @media (max-width: 768px) {
    width: 32px;
    height: 32px;
  }
`
