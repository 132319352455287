import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { utils } from 'ethers';
import networks from './networks.json';
import { CHAIN_ID } from './constants';
import Header from './components/Header';
import { SwapCard } from './components/Swap';
import Spinner from './components/Spinner';
import { getJSON, getTotalCap } from './api';
import { getLegacyMrun, getMigrator } from './api/contracts';

const AppContainer = styled.div`
  min-height: 100vh;
  background: #0f083a;
  background: radial-gradient(
    46.6% 93.31% at 50% 53.4%,
    #000000 0%,
    #170f47 100%
  );
`;

const AppWrapper = styled.div`
  min-height: 100vh;
  background-image: url(${require(`./images/MainBackground.png`)});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
`;

function App() {
  const [isLoading, setIsLoading] = useState(false);
  const [isJSONLoading, setIsJSONLoading] = useState(false);
  const [isTotalCapLoading, setIsTotalCapLoading] = useState(false);
  const [userAddress, setUserAddress] = useState('');
  const [userBalance, setUserBalance] = useState('0.00');
  const [chainId, setChainId] = useState('');
  const [json, setJSON] = useState();
  const [totalCap, setTotalCap] = useState();
  const [allowance, setAllowance] = useState(0);
  const [alreadyMigrated, setAlreadyMigrated] = useState(0);
  const [totalMigrated, setTotalMigrated] = useState(0);

  function getAllowance() {
    const MetarunToken = getLegacyMrun();
    MetarunToken.allowance(
      userAddress,
      networks[CHAIN_ID].migratorContract.address
    )
      .then((res) => {
        setAllowance(res.toString());
      })
      .catch((e) => {
        console.log(e);
      });
  }

  function getAlreadyMigrated() {
    const Migrator = getMigrator();
    Migrator.migrated(userAddress)
      .then((res) => {
        setAlreadyMigrated(res);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  function getTotalMigrated() {
    const Migrator = getMigrator();
    Migrator.totalMigrated()
      .then((res) => {
        setTotalMigrated(utils.formatEther(res));
      })
      .catch((e) => {
        console.log(e);
      });
  }

  function onAddressChanged(accounts) {
    setUserAddress(utils.getAddress(accounts[0]));
  }

  function onChainChanged() {
    setUserBalance('0.00');
    window.ethereum
      .request({ method: 'eth_chainId' })
      .then((chain) => setChainId(chain));
  }

  useEffect(() => {
    if (chainId === networks[CHAIN_ID].params.chainId && userAddress) {
      setIsLoading(true);
      window.ethereum
        .request({ method: 'eth_getBalance', params: [userAddress, 'latest'] })
        .then((balance) => {
          const num = Number(utils.formatEther(balance));
          setUserBalance(num.toFixed(2));
          setIsLoading(false);
        })
        .catch(() => setIsLoading(false));
    }
  }, [userAddress, chainId]);

  useEffect(() => {
    if (userAddress) {
      setIsLoading(true);
      window.ethereum
        .request({ method: 'eth_chainId' })
        .then((chain) => {
          setChainId(chain);
          setIsLoading(false);
        })
        .catch(() => setIsLoading(false));
    }
  }, [userAddress]);

  useEffect(() => {
    if (userAddress) {
      setIsJSONLoading(true);
      getJSON(userAddress)
        .then((res) => {
          setJSON(res);
          setIsJSONLoading(false);
        })
        .catch((e) => {
          console.log(e);
          setIsJSONLoading(false);
        });
    }
  }, [userAddress]);

  useEffect(() => {
    if (userAddress) {
      setIsTotalCapLoading(true);
      getTotalCap()
        .then((res) => {
          setTotalCap(res);
          setIsTotalCapLoading(false);
        })
        .catch((e) => {
          console.log(e);
          setIsTotalCapLoading(false);
        });
    }
  }, [userAddress]);

  useEffect(() => {
    if (chainId === networks[CHAIN_ID].params.chainId && userAddress) {
      getAllowance();
      getAlreadyMigrated();
      getTotalMigrated();
    }
  }, [userAddress, chainId]);

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on('accountsChanged', onAddressChanged);
      window.ethereum.on('chainChanged', onChainChanged);
    }
    return () => {
      if (window.ethereum) {
        window.ethereum.removeListener('accountsChanged', onAddressChanged);
        window.ethereum.removeListener('chainChanged', onChainChanged);
      }
    };
  }, []);

  return (
    <AppContainer>
      <AppWrapper>
        <Header
          chainId={chainId}
          userAddress={userAddress}
          setUserAddress={setUserAddress}
          userBalance={userBalance}
        />
        {isLoading || isJSONLoading || isTotalCapLoading ? (
          <div style={{ marginTop: '100px' }}>
            <Spinner size={'60px'} />
          </div>
        ) : (
          <div style={{ padding: '24px' }}>
            <SwapCard
              userAddress={userAddress}
              chainId={chainId}
              json={json}
              allowance={allowance}
              alreadyMigrated={alreadyMigrated}
              getAllowance={getAllowance}
              getAlreadyMigrated={getAlreadyMigrated}
              totalCap={totalCap}
              totalMigrated={totalMigrated}
            />
          </div>
        )}
      </AppWrapper>
    </AppContainer>
  );
}

export default App;
