import styled from 'styled-components'

export const ProgressContainer = styled.div`
  height: 8px;
  width: 100%;
  background-color: #e0e0de;
  background: rgba(73, 158, 255, 0.2);
  border-radius: 8px;
  overflow: hidden;
`
export const ProgressFiller = styled.div`
  height: 100%;
  width: ${({ completed }) => {
    if (completed) {
      return `${completed}%`
    }
    return '100%'
  }};
  background: linear-gradient(
    88.82deg,
    #489dfe 11.9%,
    #3970fe 45.06%,
    #5e51ff 76.01%
  );
  border-radius: inherit;
  text-align: right;
`
