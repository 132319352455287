import React from 'react'
import ReactDOM from 'react-dom/client'
import { GlobalStyles } from './styles'
import { GlobalFonts } from './fonts'
import App from './App'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <GlobalFonts />
    <GlobalStyles />
    <App />
  </React.StrictMode>
)
